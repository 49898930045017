<template>
  <div class="content">
  <van-nav-bar
  :title="langs.t1"
  :left-text="langs.t2"
  :right-text="langs.t3"
  left-arrow
  @click-left="onClickLeft"
  @click-right="onClickRight"
/>

<div class="dajiange"></div>
<!-- <div class="zhifu public_flex">
   <div class="zf1"  @click="show_z"></div>
   <div class="zf2"  @click="cz"></div>
</div> -->


<div class="list">


<div  >

<div class="cz_list"   v-for="(item1,index) in lists" :key="index"  >
  <!-- <div class="icon tag0">I</div> -->
  <div  class="order_no public_flex">
    <div class="order">{{item1.name}}
    </div>
     <div class="status">
      <div class="kuang">{{item1.shouyi}}/1000</div>
     </div>
  </div>

  <div class="money public_flex">
      <div class="money1">{{langs.t4}}:</div>
       <div class="money3">{{item1.price}}BDT</div>
    </div>
      <div class="money public_flex">
      <div class="money1">{{langs.t5}}:</div>
       <div class="money3">{{item1.shouyi}}/1000</div>
    </div>

    <div class="money public_flex">
      <div class="money1">{{langs.t6}}:</div>
       <div class="money3">{{item1.fengbi}} {{langs.t10}}s</div>
    </div>
     <div class="btn" @click="buy22(item1)" style="margin-top: 20px;">   <van-button block round type="success" size="small" color="#07c160">&nbsp;&nbsp;&nbsp;&nbsp;
        {{langs.t7}}&nbsp;&nbsp;&nbsp;&nbsp;</van-button>
      </div>
   
  </div>
  <van-empty :description="langs.tp15" v-if="lists.length<=0"/>
</div>

  



  <div style="height:20px"></div>
</div>


<div style="height:80px"></div>

<van-action-sheet v-model="show1_1" :title="langs.t7">
  <div class="buy">
    <div class="name public_flex">
         <div class="n1">{{langs.t8}}</div>
         <div class="n2">{{ buy1.name }}</div>
    </div>
    <div class="value public_flex">
         <div class="n1">{{langs.t9}}</div>
         <div class="n2"> BDT <van-stepper v-model="value" step="10"  theme="round" button-size="22" :min="buy1.price" max="10000000"/></div>
    </div>
    <div class="price public_flex">
         <div class="p1">{{langs.t11}}</div>
         <div class="p2">BDT{{ (buy1.shouyi*value*buy1.fengbi)/1000 }} </div>
    </div>
    <!-- <div class="name public_flex" style="height: 70px;">
         <div class="n1">Pay the password</div>
         <div class="n2" style="width: 70%;border: 1px solid #eee;">   
          <van-field v-model="pass" type="password" label="" placeholder="Enter the password for payment" />

        </div>
    </div> -->

    <div class="btn" @click="buy11"><van-button round type="success"  size="large" color="#07c160">
      {{langs.t12}}</van-button></div>
      <div style="height:200px"></div>
  </div>
</van-action-sheet>
<Footer :lang_list="lang_list" :lang="langs" />
</div>

</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { List } from "vant";
import { Toast } from "vant";
import * as api from '@/api';
import * as local from "@/libs/local";
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';
import { NavBar } from 'vant';
import { Empty } from 'vant';
import { Loading } from 'vant';
import { Button } from 'vant';
import { PasswordInput, NumberKeyboard } from 'vant';
import Footer from './lyout/footer.vue';
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Button);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
export default {
  name: "Home123213",
  components: {
    //HelloWorld
    Footer,
  },
data() {
  return {
    show1_1:false,
    user:{},
    lists:[],
    value:1,
    pass:"",
   sub: false,
   showKeyboard:false,
   lang:0,
   lang_list: [],
      select_lang: {},
      langs: {},
      cate: [],
   buy1:{},
  };
},
created() {
 this.get_imdex_data()
   this.lang=local.readInfo('setting_lang')==undefined||local.readInfo('setting_lang')==null?0:local.readInfo('setting_lang')
},
methods: {
  get_imdex_data:function(){
        api.fund({
        token: local.readInfo('jwt'),
        lang:local.readInfo('car_lang'),
        }, (err, data) => {
          if (!err && data.code === 1) {
              this.user=data.data.user
              this.lists=data.data.list
              this.lang_list = data.lang_list;
              this.select_lang = data.select_lang;
               this.langs = data.lang;
          } else {
              console.log(data)
              Toast("Network error")
              this.sub = false
         
          }
        
        })
    },
  onClickLeft() {
    this.$router.go(-1); //返回上一层
  },   
  onClickRight() {
    let _this = this;
      _this.$router.push({ name: "my_fund", query: { redId: 1111 } });
  },
  buy22(item){
    this.show1_1=true
    this.value=item.price
    this.buy1=item
  },   
  buy11(){
    if(this.sub){
      return;
    }
    this.sub==true
    Toast.loading({
        message: "loading...",
        forbidClick: true
      });
    api.buy_fund({
        token: local.readInfo('jwt'),
        id:this.buy1.id,
        price:this.value,
        pass:this.pass
        }, (err, data) => {
          this.sub=false;
          if (!err && data.code === 1) {
            //Toast(data.msg)
              Dialog.alert({
              message:    data.msg,
              confirmButtonText: this.langs.tp2,
               theme: 'round-button',
              }).then(() => {
              // on close
                this.show1_1=false
              });
         
          }else{
            if(data){
            Toast.fail(data.msg)
            }
          }
        })

  }
 
}
};
</script>

<style scoped>
.home222 {
  background-color: #fff;
max-width: 750px;
margin: 0 auto;
}
.content {
width: 100%;
height: auto;
min-height: 1624px;
background-color: #fff;
background-size: 100%;
}


.shuye img {
width: 100%;
}
.dajiange{
height: 15px;
}

.list{
width:96%;
margin: 0 auto;
min-height: 400px;
background: rgba(255, 255, 255, .7);
border-radius: 25px;

}
.list .title{
text-align: left;
color:#333;
font-weight: 700;
font-size: 30px;
line-height: 40px;
padding: 15px 20px;
box-sizing: border-box;
background: #eee;
}
.list .cz_list{
width:95%;
height: 340px;
background: white;
border-radius: 20px;
margin: 0 auto;
margin-top: 20px;
padding: 5px;
box-sizing: border-box;
box-shadow: 3px 5px 10px 1px #edebeb;
}
.list .cz_list .new_list{
  width: 100%;
}
.list .cz_list .new_list .logo{
  width:200px;
  height: 150px;
  overflow: hidden;
}
.list .cz_list .new_list .logo img{
  width:100%
}
.list .cz_list .new_list .new_content{
  width:440px;
  height: 210px;
  overflow: hidden;
}

.list .cz_list .order_no{
  width: 100%;
  height: 35px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.list .cz_list .order_no .order{
  width:50%;
  background: url("../assets/img/wd (3).png") no-repeat;
 background-size: 30px 30px;
 padding-left: 30px;
 box-sizing: border-box;
 height: 35px;
 text-align: left;
 font-size: 24px;
 font-weight: 700;
 color:#333;
 line-height: 35px;
}
.list .cz_list .order_no .status{
  width: 50%;
  height: 35px;
}
.list .cz_list .order_no .status .kuang{
  width: 230px;
  height: 33px;
  border: 1px solid #e4b025;
  padding:0 15px;
  line-height: 33px;
  font-size: 12px;
  color:#e4b025;
  margin-left: 60px;
  border-radius: 7px;
}
.list .cz_list .date{
  line-height: 35px;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;
 margin-top: 15px;
}
.list .cz_list .money{
  width: 100%;
  height: 30px;
  padding-top: 15px;
}
.list .cz_list .money .money1{
  width:50%;
  text-align: left;
  font-size: 24px;
 font-weight: 100;
 color:#999999;

}
.list .cz_list .money .money3{
  width:50%;
  text-align: right;
  font-size: 24px;
  font-weight: 100;
  color:#f13b17;
}

.buy{
  padding: 30px;
}
.buy .name{
  height: 40px;
}
.buy .name .n1{
  color:#333
}
.buy .name .n2{
  color:#999
}
.buy .value{
  height: 80px;
}
.buy .value .n1{
  color:#333
}
.buy .price{
  height: 50px;
}
.buy .price .p1{
  color:#333
}
.buy .price .p2{
  color:red;
  font-size: 28px;
}
.buy .btn{
  width:70%;
  margin: 0 auto;
  margin-top: 20px;
}
.buy>>>.van-stepper__input{
  width:80px
}
.buy >>>.van-password-input{
  margin: 0;
  margin-left: 20px;
}

</style>